.submit-container {
    flex-direction: column;
}

.submit-green {
    background: linear-gradient(90deg, rgba(0,255,104,1) 0%, rgba(221,255,230,1) 100%);
}

.submit-red {
    background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,221,221,1) 100%);
}

.submit-gray {
    background: linear-gradient(90deg, rgba(182,182,182,1) 0%, rgba(255,255,255,1) 100%);
}

.submit-status {
    font-weight: 900;
}